import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import Bus from './bus'

let refuseToAccept = false

// create an axios instance
const { CancelToken } = axios
const source = CancelToken.source()
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 15000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = token
      // config.cancelToken = source.token
    }
    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
    }
    return config
  },
  (error) => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data
    // 正常成功
    if (res.code === 200) {
      refuseToAccept = false
      return res
    }
    // 文件流返回
    if (res.code === undefined) {
      refuseToAccept = false
      return response
    }
    // 三次密码错误需要验证码，验证码过期
    if (res.code === 422 || res.code === 423) {
      return res
    }
    // 鉴权出错后续请求不再弹出登录提示弹框
    if (refuseToAccept) {
      return false
    }
    // token过期、登录被挤等
    if (res.code === 403) {
      refuseToAccept = true
      source.cancel()
      MessageBox.confirm(res.msg, '提示', {
        confirmButtonText: '确定',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {
        Bus.$emit('Login')
      })
      return false
    }
    // 其他情况
    source.cancel()
    Message({
      message: res.msg || 'Error',
      type: 'error',
      showClose: true
    })
    return Promise.reject(new Error(res.code || 'Error'))
  },
  (error) => {
    source.cancel()
    console.log(error)
    return Promise.reject(error)
  }
)

export default service
