import request from '@/utils/request'

/**
 * 登录
 * @param {string} name 姓名
 * @param {string} phone 电话
 * @param {string} message 信息
 * @returns
 */
// eslint-disable-next-line import/prefer-default-export
export function saveMsg(data) {
  return request({
    url: '/api/v1/system/ops/leaveComment/save',
    method: 'post',
    data
  })
}
