<template>
  <div
    class="home"
    @click="topSHowConcate=false,topSHowConcate2=false"
  >
    <!-- 顶部栏 -->
    <div
      class="topBar"
      @click="topSHowConcate=false"
    >
      <div
        class="topBar-left"
      >
        <img
          src="../assets/home/logo.png"
        >
        <span>四川智载十方科技有限公司</span>
      </div>
      <div
        class="topBar-right"
        @click.stop="topSHowConcate=true,topSHowConcate2=false"
      >
        <img
          src="../assets/home/icon_lxwm.png"
        >
        <span>联系我们</span>

      </div>

    </div>
    <!-- banner -->
    <div
      class="banner"
      @click="openDia"
    >
      <img src="../assets/home/banner.png">
    </div>
    <div class="center-All">
      <!-- 第一块文字介绍 -->
      <div
        class="introductionText"
      >
        <div
          class="introductionText-text"
          @click="openDia"
        >
          <div class="textOne">中央厨房核心运营模式四大利益共同体</div>
          <div class="textTwo">除了央厨自身运营能效，我们还关注到央厨核心关联主体能效…</div>
        </div>
        <div
          class="flexFour"
          @click="openDia"
        >
          <div
            v-for="(item,index) in textList"
            :key="index"
          >
            <div
              v-if="index%2!==0"
              class="img56"
            >
              <img
                src="../assets/home/line.png"
                class="img56"
              >
            </div>
            <div v-if="index%2===0">
              <div class="topContent">
                <img
                  :src="item.img"
                  class="img75"
                >
                <div class="textBlod">{{ item.content }}</div>
                <div
                  v-for="(v,i) in item.list"
                  :key="i"
                  class="text"
                >
                  {{ v }}
                </div>
              </div>
              <img
                :src="item.imgBottm"
                class="img267"
              >
            </div>
          </div>
        </div>
        <div
          class="bgOne"
          @click="openDia"
        >
          <!-- <img
            src="../assets/home/bg_text.png"
          > -->
          <div class="bgOne_textOne">解决所有关联主体难点，只需要打通央厨从毛菜入库到客户签收的全链流数据</div>
          <div class="bgOne_textTwo">——智载通厨ERP信息化管理系统</div>
        </div>
        <div
          class="flexFour"
          @click="openDia"
        >
          <div
            v-for="(item,index) in textListTwo"
            :key="index"
          >
            <div
              v-if="index%2!==0"
              class="img56"
            >
              <img
                src="../assets/home/line.png"
                class="img56"
              >
            </div>
            <div
              v-if="index%2===0"
              class="systemText"
            >
              <div style="">
                <div
                  v-for="(v,i) in item.list"
                  :key="i"
                >
                  {{ v }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 第二块文字介绍 -->
      <div class="textIntroduce">
        <div class="textIntroduce-bg">
          <div class="textIntroduce-bg-box">
            <div
              v-for="(item,index) in advantageList"
              :key="index"
              class="textIntroduce-bg-child"
              @click="openDia"
            >
              <img
                class="leftImg"
                :src="item.img"
              >
              <div class="rightText">
                <div>{{ item.title }}</div>
                <div>{{ item.content }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 使用erp后 -->
      <div class="graphicDescription">
        <div class="topTitle">
          使用智载通厨ERP信息化管理系统后
        </div>
        <div
          style="display:flex"
          @click="openDia"
        >
          <div class="imgDiv">
            <img src="../assets/home/tu.png">
          </div>
          <div class="textDiv">
            <div
              v-for="(item,index) in afterUseList"
              :key="index"
            >
              <img
                :src="item.iconI"
                class="iconI"
              >
              <div>
                <div class="title">{{ item.title }}</div>
                <div class="content">
                  {{ item.content }}
                  <span :style="index===0?'color:#004BD4':'color:#FFA800'">
                    {{ item.contentTwo }}</span>
                </div>
              </div>
            </div>
            <!-- <img src="../assets/home/tu.png"> -->
          </div>
        </div>
      </div>
      <!-- 运营模式 -->
      <div
        class="operationMode"
        @click="openDia"
      >
        <img src="../assets/home/4.png">
      </div>
      <!-- 模块介绍 -->
      <div
        class="mode"
        @click="openDia"
      >
        <div class="imgBox leftImg">
          <img src="../assets/home/tu1.png">
        </div>
        <div class="textBox">
          <div class="titleTop">
            指令中心
            <img src="../assets/home/bg(3).png">
          </div>
          <div class="content contentMar">
            一个订单，可根据央厨各环节时间节点规划，按需拆解为各环节指令，并按周期统一汇总
          </div>
          <div class="content">数据出现变动时，系统以异动事件形式下发指令</div>
        </div>
      </div>
      <div
        class="mode"
        style="background:#F3F5FA"
        @click="openDia"
      >
        <div class="textBox leftTextOne">
          <div class="titleTop">
            仓储中心
            <img src="../assets/home/bg(3).png">
          </div>
          <div class="content contentMar">
            <div class="title">智能盘点</div>
            <div>智能生成盘点表，快速完成盘点；</div>
            <!-- <div>自动统计成本，并做盈亏调整基于生鲜企业每日盘点现状，系统可实现智能生成盘点表，快速完成盘点；</div>
          <div>系统自动统计成本，并做盈亏调整。</div> -->
            <div>自动统计成本，并做盈亏调整基于生鲜企业每日盘点现状。</div>
            <div class="content">
              <div class="title">智能指令</div>
              <div>自动完成预出入库、先入先出自动创建拣料任务
              </div>
            </div>
          </div>
        </div>
        <div class="imgBoxTwo">
          <img src="../assets/home/tu2.png">
        </div>
      </div>
      <div
        class="mode"
        @click="openDia"
      >
        <div class="imgBox leftImgTwo">
          <img src="../assets/home/tu3.png">
        </div>
        <div class="textBox">
          <div class="titleTop">
            生产中心
            <img src="../assets/home/bg(3).png">
          </div>
          <div class="content contentMar">
            <div class="title">全品类BOM：统一所有品类的菜品标准</div>
            <div>精确记录每个产品的出成数量及出成率菜谱关联原料用量，精准核算菜谱成本及毛利</div>
            <div class="content">
              <div class="title">产线、工序、工艺三端联动</div>
              <div>系统自动生成标准工艺</div>
              <div>系统涵盖了央厨生产加工的8大工序，完全满足央厨生产加工90%以上的需求；</div>
              <div>产线自动规划、自动排产</div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="mode"
        style="background:#F3F5FA"
        @click="openDia"
      >
        <div class="textBox leftTwo">
          <div class="titleTop">
            采购中心
            <img src="../assets/home/bg(3).png">
          </div>
          <div class="content contentMar">
            <div class="title">采购需求自动生成</div>
            <div>自动生成采购单、提前生成采购计划、自动关联供应商</div>
          </div>
        </div>
        <div class="imgBoxFour">
          <img src="../assets/home/tu4.png">
        </div>
      </div>
      <div
        class="mode"
        @click="openDia"
      >
        <div class="imgBox leftImg">
          <img src="../assets/home/tu5.png">
        </div>
        <div class="textBox">
          <div class="titleTop">
            食品安全
            <img src="../assets/home/bg(3).png">
          </div>
          <div class="content contentMar">
            <div class="title titleTwo">引入了已服务于<span class="blueColor">13000+</span>食堂从业人员的
              智慧食安监管产品</div>
            <div>从产地到餐桌，食品安全全程溯源独创算法提前研判风险</div>
          </div>
        </div>
      </div>
      <!-- 客户案例 -->
      <div class="customerCase">
        <div class="titleTop">客户案例</div>
        <div class="image_All">
          <div
            v-for="(item,index) in customerList"
            :key="index"
            class="imageBox"
            @click="openDia"
          >
            <img
              :src="item.iconI"
            >
            <div class="complay">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 免费试用 -->
    <div class="freeTrial">
      <div class="titleTop">免费试用</div>
      <div class="formBox">
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
        >
          <div
            class="inlineBox"
          >
            <el-form-item
              label="称呼"
              prop="name"
            >
              <el-input
                v-model="ruleForm.name"
                placeholder="请输入您的姓名"
                type="text"
                show-word-limit
                :maxlength="20"
                class="input-ui"
              />
            </el-form-item>
            <el-form-item
              label="联系电话"
              prop="phone"
            >
              <el-input
                v-model="ruleForm.phone"
                placeholder="请输入您的联系电话"
                class="input-ui"
              />
            </el-form-item>
          </div>
          <div
            class="inlineBox2"
          >
            <el-form-item
              label="留言内容"
              prop="message"
              class="leaveMsg"
            >
              <el-input
                v-model="ruleForm.message"
                placeholder="请输入您的留言内容"
                :maxlength="100"
                style="width:100%"
                class="input-ui"
                show-word-limit
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4}"
              />
            </el-form-item>
          </div>
          <div class="subBtn">
            <div @click="submit">提交</div>
          </div>
        </el-form>
      </div>
    </div>
    <div class="footer">
      <div class="text">
        <span>Copyright © 四川智载十方科技有限公司 </span>
        <!-- <span>地址：四川省成都市金牛区三泰科技魔方创意园C1栋04楼 </span> -->
      </div>
    </div>
    <div style="height:10px" />

    <el-dialog
      title=""
      :show-close="false"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      class="freeTrialDia"
    >
      <div class="titleTop">联系我们</div>
      <div class="formBox">
        <el-form
          ref="ruleForm1"
          :model="ruleForm1"
          :rules="rules"
          @close="handleClose"
        >
          <div
            class="inlineBox"
          >
            <el-form-item
              label="称呼"
              prop="name"
            >
              <el-input
                v-model="ruleForm1.name"
                placeholder=""
                :maxlength="20"
                type="text"
                show-word-limit
                class="input-ui"
              />
            </el-form-item>
            <el-form-item
              label="联系电话"
              prop="phone"
            >
              <el-input
                v-model="ruleForm1.phone"
                placeholder=""
                class="input-ui"
              />
            </el-form-item>
          </div>
          <div
            class="inlineBox2"
          >
            <el-form-item
              label="留言内容"
              prop="message"
              class="leaveMsg"
            >
              <el-input
                v-model="ruleForm1.message"
                placeholder=""
                style="width:100%"
                class="input-ui"
                :maxlength="100"
                type="textarea"
                show-word-limit
                :autosize="{ minRows: 2, maxRows: 4}"
              />
            </el-form-item>
          </div>
          <div class="subBtn">
            <div
              class="cancle"
              @click="handleClose"
            >取消</div>
            <div
              class="sure"
              @click="submit1"
            >提交</div>
          </div>
        </el-form>
      </div>
    </el-dialog>
    <div
      v-show="scrollShow"
      class="floatContant"
      @click.stop="topSHowConcate2=true,topSHowConcate=false"
    >
      <img src="../assets/home/icon_contact.png">
      <div class="contantBtn">联系我们</div>

    </div>
    <div
      v-if="topSHowConcate"
      class="concateImage"
      @click.stop="topSHowConcate=true"
    >
      <div class="leftQRCode">
        <div class="CONTACT">
          <div>CONTACT</div>
          <div>INFORMATION</div>
        </div>
        <div class="concate">联系我们</div>
        <div class="QRCode">
          <div>
            <div><img src="../assets/home/qrcode.jpg"></div>
            微信公众号
          </div>
          <div>
            <div><img src="../assets/home/qrcode2.png"></div>
            市场经理微信号
          </div>
          <!-- <div><img src=".."/></div> -->
        </div>
      </div>
      <div class="lineVertica">
        <!-- <img src="../assets/home/line_vertical.png"> -->
      </div>
      <div class="phoneAll">
        <!-- <div class="modeContent">
          <img
            class="leftIcon"
            src="../assets/home/icon_dh.png"
          >
          <div class="rightContent">
            <div class="title">公司电话</div>
            <div class="content">028-6183-7529</div>
          </div>
        </div> -->
        <div class="modeContent">
          <img
            class="leftIcon"
            src="../assets/home/icon_sj.png"
          >
          <div class="rightContent">
            <div class="title">商务电话</div>
            <!-- <div class="content">130-8669-0999</div>
            <div class="content">177-1139-1406</div>
            <div class="content">158-8203-9356</div> -->
            <div class="content">183-2856-7024</div>
            <div class="content">158-8234-7100</div>
          </div>
        </div>
        <div class="modeContent">
          <img
            class="leftIcon"
            src="../assets/home/icon_dz.png"
          >
          <div class="rightContent">
            <div class="title">地址</div>
            <!-- <div class="content">成都市金牛区蜀西路42号 三泰魔方C1座4F-A区</div>  -->
            <div class="content">四川省成都市大邑县青霞街道兴业三路2号附1号</div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="topSHowConcate2"
      class="concateImage concateImage2"
      @click.stop="topSHowConcate2=true"
    >
      <div class="leftQRCode">
        <div class="CONTACT">
          <div>CONTACT</div>
          <div>INFORMATION</div>
        </div>
        <div class="concate">联系我们</div>
        <div class="QRCode">
          <div>
            <div><img src="../assets/home/qrcode.jpg"></div>
            微信公众号
          </div>
          <div>
            <div><img src="../assets/home/qrcode2.png"></div>
            市场经理微信号
          </div>
          <!-- <div><img src=".."/></div> -->
        </div>
      </div>
      <div class="lineVertica">
        <!-- <img src="../assets/home/line_vertical.png"> -->
      </div>
      <div class="phoneAll">
        <!-- <div class="modeContent">
          <img
            class="leftIcon"
            src="../assets/home/icon_dh.png"
          >
          <div class="rightContent">
            <div class="title">公司电话</div>
            <div class="content">028-6183-7529</div>
          </div>
        </div> -->
        <div class="modeContent">
          <img
            class="leftIcon"
            src="../assets/home/icon_sj.png"
          >
          <div class="rightContent">
            <div class="title">商务电话</div>
            <!-- <div class="content">130-8669-0999</div>
            <div class="content">177-1139-1406</div>
            <div class="content">158-8203-9356</div> -->
            <div class="content">183-2856-7024</div>
            <div class="content">158-8234-7100</div>
          </div>
        </div>
        <div class="modeContent">
          <img
            class="leftIcon"
            src="../assets/home/icon_dz.png"
          >
          <div class="rightContent">
            <div class="title">地址</div>
            <!-- <div class="content">成都市金牛区蜀西路42号 三泰魔方C1座4F-A区</div> -->
            <div class="content">四川省成都市大邑县青霞街道兴业三路2号附1号</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { saveMsg } from '../api/home'

export default {
  components: {
  },
  data() {
    return {
      transitionName: 'slide-left',
      exclude: [],
      dialogFormVisible: false,
      topSHowConcate: false,
      topSHowConcate2: false,
      scrollShow: true,
      textList: [{
        img: require('../assets/home/icon_xfzt.png'),
        imgBottm: require('../assets/home/icon_jt .png'),
        content: '消费主体',
        list: ['收费核验复杂困难', '食品安全压力大', '营养、分量因季节不均', '信息留痕困难']
      }, {

      }, {
        img: require('../assets/home/icon_gys.png'),
        imgBottm: require('../assets/home/icon_jt .png'),
        content: '供应商',
        list: ['供应商评价体系混乱', '平台兼容性差，数据无法共享', '安全问题权责难追', '菜谱品类多，单体供应量小']
      }, {

      }, {
        img: require('../assets/home/icon_scycd.png'),
        imgBottm: require('../assets/home/icon_jt .png'),
        content: '食材原产地',
        list: ['自然规律为唯一种植', '准则供需不匹配，效益', '无法最大化']
      }, {

      }, {
        img: require('../assets/home/icon_yczs.png'),
        imgBottm: require('../assets/home/icon_jt .png'),
        content: '央厨自身',
        list: ['运营成本高', '库房易堆积', '成本难统计', '加工靠经验']
      }],
      textListTwo: [{
        list: ['收费退费便捷管理', '食安问题0压力', '稳定的生均成本']
      }, {

      }, {
        list: ['成熟的市场供需模型', '稳定的成本模型', '高兼容性的数据平台']
      }, {

      }, {
        list: ['产地无闲季闲地', '央厨获取稳定货源']
      }, {

      }, {
        list: ['打通营养餐、热链、冷链', '全场景下的采购-仓储', '- 生产-配送全链流数据']
      }],
      advantageList: [{
        img: require('../assets/home/icon_shuju.png'),
        title: '数据精准及时',
        content: '打通全集团的采购、生产、仓储、配送全链数据流，智能统筹分析'
      }, {
        img: require('../assets/home/icon_xiaoneng.png'),
        title: '效能成本可控',
        content: '指令中心、配送中心、生产中心、采购中心联动管控'
      }, {
        img: require('../assets/home/icon_guanli .png'),
        title: '无缝过程管理',
        content: '从检验结果转变为全过程控制，保障任一环节数据、流程无错漏'
      }],
      afterUseList: [{
        iconI: require('../assets/home/icon_jt.png'),
        title: '短期内',
        content: '以150个学校为准，实现',
        contentTwo: '节省1个计划员、2个区域经理'
      }, {
        iconI: require('../assets/home/icon_jt.png'),
        title: '长期后',
        content: '以150个学校为准，实现',
        contentTwo: '只需1个计划员、2个区域经理'
      }],
      customerList: [{
        iconI: require('../assets/home/case/tu_1.png'),
        name: '昆明链融供应链管理有限公司'
      }, {
        iconI: require('../assets/home/case/tu_2.png'),
        name: '链融红河'
      }, {
        iconI: require('../assets/home/case/tu_3.png'),
        name: '麦金地（平昌）中央厨房'
      }, {
        iconI: require('../assets/home/case/tu_4.png'),
        name: '麦金地（通江）中央厨房'
      }, {
        iconI: require('../assets/home/case/tu_5.png'),
        name: '麦金地（巫山）中央厨房'
      }, {
        iconI: require('../assets/home/case/tu_6.png'),
        name: '麦金地（越西）中央厨房'
      }],
      ruleForm: {
        name: '',
        phone: '',
        message: ''
      },
      ruleForm1: {
        name: '',
        phone: '',
        message: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入您的姓名', trigger: 'blur' }
        ],
        phone: [{
          required: true,
          message: '请输入您的联系电话',
          trigger: ['blur', 'change']
        },
        {
          required: true,
          message: '电话格式不正确',
          pattern: /^((0\d{2,3}-?\d{7,8})|(1[3465789]\d{9}))$/,
          trigger: 'blur'
        }],
        message: [
          { required: true, message: '请输入您的留言内容', trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll(e) {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      console.log(scrollTop, e)
      if (scrollTop >= 7580) {
        this.scrollShow = false
      } else {
        this.scrollShow = true
      }
    },
    openDia() {
      this.dialogFormVisible = true
      this.topSHowConcate = false
    },
    handleClose() {
      this.ruleForm1 = {
        name: '',
        phone: '',
        message: ''
      }
      this.$refs.ruleForm1.resetFields()
      this.dialogFormVisible = false
    },
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          console.log('111111')
          const data = JSON.parse(JSON.stringify(this.ruleForm))
          saveMsg(data).then((res) => {
            this.$message.success('保存成功')
            this.ruleForm = {
              name: '',
              phone: '',
              message: ''
            }
            this.$refs.ruleForm.resetFields()
          })
        }
      })
    },
    submit1() {
      this.$refs.ruleForm1.validate((valid) => {
        if (valid) {
          console.log('111111')
          const data = JSON.parse(JSON.stringify(this.ruleForm1))
          saveMsg(data).then((res) => {
            this.$message.success('保存成功')
            this.ruleForm1 = {
              name: '',
              phone: '',
              message: ''
            }
            this.dialogFormVisible = false
            this.$refs.ruleForm1.resetFields()
          })
        }
      })
    }
  }

}
</script>
<style lang="scss" scoped>
@import '../assets/styles/home.scss';
</style>

